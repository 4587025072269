import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useReactFlow } from 'react-flow-renderer';
import { checkValidity } from '../utils/utils';
import { useNavigate } from 'react-router-dom';

/**
 * Context for managing canvas-related state and functionality within the application.
 * Provides access to the current scenario, nodes, edges, and various other canvas-related states.
 */
const CanvasContext = createContext();

/**
 * CanvasProvider component that provides state and functionality for the canvas.
 *
 * @param {Object} props - The props for the provider.
 * @param {string} props.scenarioId - The ID of the scenario being edited.
 * @param {React.ReactNode} props.children - The child components that require canvas context.
 */
export const CanvasProvider = ({ scenarioId, children }) => {
    // State Variables
    /**
     * The current scenario object.
     * @type {Object|null}
     */
    const [scenario, setScenario] = useState(null);

    /**
     * The nodes on the canvas.
     * @type {Array<Object>}
     */
    const [nodes, setNodes] = useState([]);

    /**
     * The edges (connections) between nodes.
     * @type {Array<Object>}
     */
    const [edges, setEdges] = useState([]);

    const { getZoom, setCenter } = useReactFlow();

    /**
     * ID of the selected node.
     * @type {string|null}
     */
    const [selectedNodeId, setSelectedNodeId] = useState(null);

    /**
     * List of IDs of selected nodes.
     * @type {Array<string>}
     */
    const [selectedNodeIds, setSelectedNodeIds] = useState([]);

    /**
     * ID of the selected edge.
     * @type {string|null}
     */
    const [selectedEdgeId, setSelectedEdgeId] = useState(null);

    /**
     * ID of the node used for edge connecting.
     * @type {string|null}
     */
    const [edgeConnectingNodeId, setEdgeConnectingNodeId] = useState(null);

    /**
     * Whether select-all has been triggered.
     * @type {boolean}
     */
    const [selectAllTriggered, setSelectAllTriggered] = useState(false);

    /**
     * Groups of situations.
     * @type {Array<Object>}
     */
    const [groups, setGroups] = useState([]);

    /**
     * List of situations in the scenario.
     * @type {Array<Object>}
     */
    const [situations, setSituations] = useState([]);

    /**
     * Whether the canvas is being dragged.
     * @type {boolean}
     */
    const [isDragging, setIsDragging] = useState(false);

    /**
     * Whether the scenario has a table of contents.
     * @type {boolean}
     */
    const [hasToc, setHasToc] = useState(false);

    /**
     * The title of the scenario.
     * @type {string}
     */
    const [scenarioTitle, setScenarioTitle] = useState('');

    /**
     * Whether logging is enabled for the scenario.
     * @type {boolean}
     */
    const [isLogged, setIsLogged] = useState(false);

    /**
     * Order of logs in the scenario.
     * @type {Array<string>}
     */
    const [logOrder, setLogOrder] = useState([]);

    /**
     * Whether the scenario is being edited.
     * @type {boolean|null}
     */
    const [isEditing, setIsEditing] = useState(null);

    /**
     * Whether the scenario is published.
     * @type {boolean}
     */
    const [isPublished, setIsPublished] = useState(false);

    /**
     * The currently selected situation.
     * @type {Object|null}
     */
    const [selectedSituation, setSelectedSituation] = useState(null);

    /**
     * The currently selected choice.
     * @type {Object|null}
     */
    const [selectedChoice, setSelectedChoice] = useState(null);

    /**
     * The currently selected group.
     * @type {Object|null}
     */
    const [selectedGroup, setSelectedGroup] = useState(null);

    /**
     * Errors in the graph structure.
     * @type {Array<Object>|null}
     */
    const [graphErrors, setGraphErrors] = useState(null);

    /**
     * The currently active edit tab.
     * @type {string}
     */
    const [activeEditTab, setActiveEditTab] = useState('1');

    /**
     * Whether the TOC editor is visible.
     * @type {boolean}
     */
    const [showTocEditor, setShowTocEditor] = useState(false);

    /**
     * Whether data is loading.
     * @type {boolean}
     */
    const [loading, setLoading] = useState(false);

    /**
     * Keys available for the scenario.
     * @type {Array<Object>}
     */
    const [keys, setKeys] = useState([]);

    /**
     * Function to retrieve the current zoom level of the canvas.
     * @type {function|null}
     */
    const [getZoomFunc, setGetZoomFunc] = useState(null);

    /**
     * Function to project screen coordinates to canvas coordinates.
     * @type {function|null}
     */
    const [projectFunc, setProjectFunc] = useState(null);

    /**
     * React Flow wrapper DOM element.
     * @type {React.RefObject|null}
     */
    const [reactFlowWrapper, setReactFlowWrapper] = useState(null);

    /**
     * Whether the canvas has been centered.
     * @type {boolean}
     */
    const [hasCentered, setHasCentered] = useState(false);

    /**
     * Handles navigation
     */
    const navigate = useNavigate();



    /**
     * Centers the canvas on specific coordinates.
     *
     * @param {number} x - The x-coordinate to center on.
     * @param {number} y - The y-coordinate to center on.
     */
    const centerOnCoordinates = useCallback((x, y) => {
        const zoom = getZoom();
        setCenter(x, y, { zoom, duration: 800 });
    }, [setCenter, getZoom]);

    /**
     * useEffect: Centers the canvas on the start node or the first available node if a start node is not found.
     * Runs when the `nodes` array or `hasCentered` state changes.
     *
     * - Finds the first node with an `endpointType` of "start".
     * - If no start node exists, defaults to centering on the first node in the array.
     * - Updates the `hasCentered` state to prevent repeated centering.
     *
     * @param {Array<Object>} nodes - The list of nodes in the canvas.
     * @param {boolean} hasCentered - Whether the canvas has already been centered.
     * @param {function} centerOnCoordinates - Function to center the canvas on given coordinates.
     */
    useEffect(() => {
        if (!hasCentered && nodes && nodes.length > 0) {
            const startNode = nodes.find((node) => node.data?.endpointType === 'start');
            const nodeToCenter = startNode || nodes[0];

            if (nodeToCenter && nodeToCenter.position) {
                const { x, y } = nodeToCenter.position;
                centerOnCoordinates(x, y);
                setHasCentered(true);
            }
        }
    }, [nodes, hasCentered, centerOnCoordinates]);

    /**
     * useEffect: Initializes the canvas with data related to the specified scenario.
     * Runs when the `scenarioId` changes.
     *
     * - Fetches scenario data from the backend.
     * - Sets up state variables based on the scenario data (e.g., `isPublished`, `hasToc`, etc.).
     * - Loads canvas data (nodes and edges) or initializes a new scenario if not yet loaded.
     * - Fetches additional data such as situations, groups, and keys.
     *
     * @param {string} scenarioId - The ID of the scenario being loaded.
     */
    useEffect(() => {
        const initializeCanvas = async () => {
            try {
                const scenarioResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`);
                const scenarioData = scenarioResponse.data;
                setScenario(scenarioData);
                setIsPublished(scenarioData.isPublished);
                setHasToc(scenarioData.tableOfContents);
                setScenarioTitle(scenarioData.title);
                setIsLogged(scenarioData.isLogged);
                setLogOrder(scenarioData.logOrder);

                if (!scenarioData.isEditing) {
                    await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`, { isEditing: true });
                }

                setIsEditing(scenarioData.isEditing);

                if (scenarioData.isLoadedToCanvas) {
                    await fetchCanvasData();
                } else {
                    await loadScenario();
                }

                await fetchSituationsAndGroups();
                await fetchKeys();
            } catch (err) {
                console.error("Error initializing canvas: ", err);
            }
        };

        initializeCanvas();
    }, [scenarioId]);

    /**
     * Fetches the nodes and edges of the canvas for the current scenario from the backend.
     * Updates the `nodes` and `edges` state variables with the fetched data.
     *
     * @async
     */
    const fetchCanvasData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}`);
            const fetchedNodes = response.data.nodes;
            const fetchedEdges = response.data.edges;

            setNodes(fetchedNodes);
            setEdges(fetchedEdges);
        } catch (err) {
            console.error("Error fetching canvas data: ", err);
        }
    };

    /**
     * Fetches all keys associated with the current scenario from the backend.
     * Updates the `keys` state variable with the fetched data.
     *
     * @async
     */
    const fetchKeys = async () => {
        try {
            const keysResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/keys/all/${scenarioId}`);
            setKeys(keysResponse.data);
        } catch (err) {
            console.error("Error fetching keys: ", err);
        }
    };

    /**
     * Loads the scenario's canvas by sending a request to the backend to initialize the canvas.
     * Updates the `nodes` and `edges` state variables with the loaded data.
     *
     * @async
     */
    const loadScenario = async () => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}/load`);
            setNodes(response.data.canvas.nodes);
            setEdges(response.data.canvas.edges);
        } catch (error) {
            console.error("Error loading scenario: ", error);
        }
    };

    /**
     * Fetches all situations and groups for the current scenario from the backend.
     * Updates the `situations` and `groups` state variables with the fetched data.
     *
     * @async
     */
    const fetchSituationsAndGroups = async () => {
        try {
            const [situationsResponse, groupsResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/${scenarioId}/all`),
                axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/situation-groups/scenario/${scenarioId}`)
            ]);

            const fetchedSituations = situationsResponse.data;
            const fetchedGroups = groupsResponse.data;

            setGroups(fetchedGroups);
            setSituations(fetchedSituations);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    /**
     * Updates the nodes on the canvas by sending the updated data to the backend.
     * If no nodes are provided, it uses the current `nodes` state.
     * Updates the `nodes` state after a successful request.
     *
     * @async
     * @param {Array<Object>} [updatedNodes] - The updated nodes to be sent to the backend. Defaults to the current nodes.
     */
    const updateNodes = async (updatedNodes) => {
        try {
            const nodesToUpdate = updatedNodes || nodes;

            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}`, {
                nodes: nodesToUpdate,
                edges, // Retain the current edges
            });

            setNodes([...nodesToUpdate]);
        } catch (error) {
            console.error("Error updating nodes: ", error);
        }
    };

    /**
     * Updates the edges on the canvas by sending the updated data to the backend.
     * If no edges are provided, it uses the current `edges` state.
     * Updates the `edges` state after a successful request.
     *
     * @async
     * @param {Array<Object>} [updatedEdges] - The updated edges to be sent to the backend. Defaults to the current edges.
     */
    const updateEdges = async (updatedEdges) => {
        try {
            const edgesToUpdate = updatedEdges || edges;

            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}`, {
                nodes, // Retain the current nodes
                edges: edgesToUpdate,
            });

            setEdges([...edgesToUpdate]);
        } catch (error) {
            console.error("Error updating edges: ", error);
        }
    };

    /**
     * Centers the canvas view on a specific node by its ID.
     *
     * - Retrieves the node's position.
     * - Centers the canvas on the node with a smooth zoom transition.
     *
     * @param {string} nodeId - The ID of the node to center on.
     */
    const centerNodeInView = useCallback((nodeId) => {
        const node = nodes.find((n) => n.id === nodeId);
        if (node) {
            const { x, y } = node.position; // Get the node's position
            const zoom = getZoom(); // Get the current zoom level
            setCenter(x, y, { zoom, duration: 800 }); // Smoothly center the view
        }
    }, [nodes, setCenter, getZoom]);

    /**
     * Creates a new situation by sending the data to the backend.
     * Updates the `situations` state with the newly created situation and selects it.
     *
     * @async
     * @param {Object} newSituationData - The data for the new situation to be created.
     * @returns {Object} The newly created situation object.
     * @throws Will re-throw any error encountered during the request.
     */
    const createSituation = async (newSituationData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/${scenarioId}`, newSituationData);
            const newSituation = response.data;
            setSelectedSituation(newSituation);
            onSelectNode(newSituation._id);

            setSituations((prevSituations) => [...prevSituations, newSituation]);

            return newSituation;
        } catch (error) {
            console.error("Error adding situation: ", error);
            throw error;
        }
    };

    /**
     * Creates a new choice connecting two situations.
     * Updates the `situations` state to include the new choice in the corresponding situation.
     * Optionally, skips setting the new choice as selected (useful for copying scenarios).
     *
     * @async
     * @param {string} sourceId - The ID of the source situation for the choice.
     * @param {string} targetId - The ID of the target situation for the choice.
     * @param {boolean} hasHint - Whether the choice includes a hint.
     * @param {boolean} [copying=false] - Whether the choice is being created as part of a copy operation.
     * @returns {Object} The newly created choice object.
     * @throws Will re-throw any error encountered during the request.
     */
    const createChoice = async (sourceId, targetId, hasHint, copying = false) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/choices/${sourceId}`, {
                text: '',
                nextSituation: targetId,
                hasHint: hasHint,
            });

            const newChoice = response.data;

            if (!copying) {
                setSelectedChoice(newChoice);
            }

            setSituations((prevSituations) => {
                return prevSituations.map((situation) => {
                    if (situation._id === newChoice.situation) {
                        const updatedChoices = [...situation.choices, newChoice];
                        return { ...situation, choices: updatedChoices };
                    }
                    return situation;
                });
            });

            return newChoice;
        } catch (error) {
            console.error("Error adding choice: ", error);
            throw error;
        }
    };


    /**
     * Updates a choice's settings in the backend and updates the local state.
     *
     * - Sends a PATCH request to update the choice.
     * - Updates the `situations` state to reflect the changes in the associated situation.
     * - Updates edges if the `hasHint` property is changed.
     *
     * @async
     * @param {Object} updatedSettings - The updated settings for the choice.
     * @param {string} [choiceId=selectedEdgeId] - The ID of the choice to update. Defaults to the currently selected edge.
     * @returns {Object} The updated choice object.
     */
    const updateChoice = async (updatedSettings, choiceId = selectedEdgeId) => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/choices/choice/${choiceId}`, updatedSettings);
            const updatedChoice = response.data;

            setSituations((prevSituations) => {
                return prevSituations.map((situation) => {
                    if (situation._id === updatedChoice.situation) {
                        const updatedChoices = situation.choices.map((choice) =>
                            choice._id === updatedChoice._id ? updatedChoice : choice
                        );
                        return { ...situation, choices: updatedChoices };
                    }
                    return situation;
                });
            });

            if (updatedSettings.hasOwnProperty('hasHint')) {
                const choiceType = updatedSettings.hasHint ? 'hint' : '';
                const updatedEdges = edges.map((edge) =>
                    edge.id === choiceId ? { ...edge, data: { ...edge.data, choiceType } } : edge
                );
                updateEdges(updatedEdges);
            }

            return updatedChoice;
        } catch (error) {
            console.error(`Error updating choice settings: ${error}`);
        }
    };

    /**
     * Deletes a choice from the backend and updates the local state.
     *
     * - Sends a DELETE request to remove the choice.
     * - Updates the `situations` state to remove the deleted choice from the corresponding situation.
     * - Triggers `onCanvasClick` to clear the selection on the canvas.
     *
     * @async
     * @param {string} [choiceId=selectedEdgeId] - The ID of the choice to delete. Defaults to the currently selected edge.
     * @throws Will re-throw any error encountered during the request.
     */
    const deleteChoice = async (choiceId = selectedEdgeId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/choices/choice/${choiceId}`);

            setSituations((prevSituations) => {
                return prevSituations.map((situation) => {
                    if (situation.choices.some((choice) => choice._id === choiceId)) {
                        const updatedChoices = situation.choices.filter((choice) => choice._id !== choiceId);
                        return { ...situation, choices: updatedChoices };
                    }
                    return situation;
                });
            });

            onCanvasClick();

            console.log(`Choice ${choiceId} deleted successfully`);
        } catch (error) {
            console.error(`Error deleting choice: ${error}`);
            throw error;
        }
    };

    /**
     * Updates a situation's data in the backend and updates the local state.
     *
     * - Sends a PATCH request to update the situation.
     * - Updates the `situations` state to reflect the changes.
     * - Updates the canvas nodes if the `isStart` or `isEnd` properties are modified.
     *
     * @async
     * @param {Object|FormData} updatedSituationData - The updated data for the situation. Can be a JSON object or FormData.
     * @param {string} [situationId=selectedNodeId] - The ID of the situation to update. Defaults to the currently selected node.
     * @returns {Object} The updated situation object.
     */
    const updateSituation = async (updatedSituationData, situationId = selectedNodeId) => {
        try {
            let response;

            if (updatedSituationData instanceof FormData) {
                response = await axios.patch(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/situations/situation/${situationId}`,
                    updatedSituationData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            } else {
                response = await axios.patch(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/situations/situation/${situationId}`,
                    updatedSituationData
                );
            }

            const updatedSituation = response.data;

            setSituations((prevSituations) =>
                prevSituations.map((situation) =>
                    situation._id === updatedSituation._id ? updatedSituation : situation
                )
            );

            if (updatedSituationData.hasOwnProperty('isStart') || updatedSituationData.hasOwnProperty('isEnd')) {
                let endpointType = '';

                if (updatedSituationData.isStart) {
                    endpointType = 'start';
                } else if (updatedSituationData.isEnd) {
                    endpointType = 'end';
                }

                const updatedNode = nodes.find((node) => node.id === situationId);
                if (updatedNode) {
                    const updatedNodes = nodes.map((node) =>
                        node.id === situationId
                            ? {
                                ...updatedNode,
                                data: {
                                    ...updatedNode.data,
                                    endpointType,
                                },
                            }
                            : node
                    );

                    updateNodes(updatedNodes);
                }
            }

            return updatedSituation;
        } catch (error) {
            console.error("Error updating situation: ", error);
        }
    };

    /**
     * Deletes a situation from the backend and updates the local state.
     *
     * - Sends a DELETE request to remove the situation.
     * - Updates the `situations` state to remove the deleted situation.
     * - Triggers `onCanvasClick` to clear the selection on the canvas.
     *
     * @async
     * @param {string} situationId - The ID of the situation to delete.
     */
    const deleteSituation = async (situationId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/situation/${situationId}`);

            setSituations((prevSituations) =>
                prevSituations.filter((situation) => situation._id !== situationId)
            );

            onCanvasClick();
        } catch (error) {
            console.error("Error deleting situation: ", error);
        }
    };


    /**
     * Creates a new group by sending the provided data to the backend.
     * Updates the `groups` state to include the new group and sets it as the selected group.
     *
     * @async
     * @param {Object} groupData - The data for the new group to be created.
     * @returns {Object} The newly created group object.
     * @throws Will re-throw any error encountered during the request.
     */
    const createGroup = async (groupData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/situation-groups/${scenarioId}`, groupData);
            const newGroup = response.data;

            setGroups((prevGroups) => [...prevGroups, newGroup]);
            setSelectedGroup(newGroup);

            return newGroup;
        } catch (error) {
            console.error("Error creating group: ", error);
            throw error;
        }
    };

    /**
     * Updates a group in the backend with the provided data.
     * Updates the `groups` state to reflect the changes.
     *
     * @async
     * @param {Object|FormData} groupData - The updated data for the group. Can be a JSON object or FormData.
     * @returns {Object} The updated group object.
     * @throws Will re-throw any error encountered during the request.
     */
    const updateGroup = async (groupData) => {
        try {
            let response;

            if (groupData instanceof FormData) {
                response = await axios.patch(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/situation-groups/${selectedGroup._id}`,
                    groupData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            } else {
                response = await axios.patch(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/situation-groups/${selectedGroup._id}`,
                    groupData
                );
            }

            const updatedGroup = response.data;

            setGroups((prevGroups) =>
                prevGroups.map((group) =>
                    group._id === updatedGroup._id ? updatedGroup : group
                )
            );

            return updatedGroup;
        } catch (error) {
            console.error("Error updating group: ", error);
            throw error;
        }
    };

    /**
     * Deletes a group and optionally updates the situations and nodes that belong to the group.
     *
     * - Sends a DELETE request to remove the group from the backend.
     * - Updates the `groups` state to remove the deleted group.
     * - If `groupSituations` is provided, clears the `situationGroup` property for affected situations.
     * - Updates the nodes' `fillColor` property for affected nodes.
     *
     * @async
     * @param {string} groupId - The ID of the group to delete.
     * @param {Array<Object>} [groupSituations=[]] - The situations belonging to the group. Defaults to an empty array.
     * @throws Will log any error encountered during the request.
     */
    const deleteGroup = async (groupId, groupSituations = []) => {
        try {
            const situationIds = groupSituations.length > 0 ? groupSituations.map((situation) => situation._id) : [];

            await axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/situation-groups/${groupId}`, {
                data: { situationIds },
            });

            setGroups((prevGroups) => prevGroups.filter((group) => group._id !== groupId));

            if (situationIds.length > 0) {
                setSituations((prevSituations) =>
                    prevSituations.map((situation) =>
                        situationIds.includes(situation._id)
                            ? { ...situation, situationGroup: null }
                            : situation
                    )
                );

                const updatedNodes = nodes.map((node) =>
                    situationIds.includes(node.id)
                        ? { ...node, data: { ...node.data, fillColor: '#FFFFFF' } }
                        : node
                );
                await updateNodes(updatedNodes);
            }

            onCanvasClick();
        } catch (error) {
            console.error("Error deleting situation group: ", error);
        }
    };

    
    
    /**
     * Retrieves a situation by its ID from the local `situations` state.
     * If found, sets it as the selected situation and selects the corresponding node on the canvas.
     *
     * @async
     * @param {string} situationId - The ID of the situation to retrieve.
     * @throws Will log an error if the situation is not found or if an error occurs during execution.
     */
    const getSituation = async (situationId) => {
        try {
            const situation = situations.find((situation) => situation._id === situationId);
            if (!situation) {
                throw new Error("Situation not found");
            }
            setSelectedSituation(situation);
            onSelectNode(situationId);
        } catch (error) {
            console.error("Error fetching situation: ", error);
        }
    };

    /**
     * Retrieves a choice by its ID from the backend.
     * Sets it as the selected choice and selects the corresponding edge on the canvas.
     *
     * @async
     * @param {string} choiceId - The ID of the choice to retrieve.
     * @throws Will log an error if the request fails or if an error occurs during execution.
     */
    const getChoice = async (choiceId) => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/choices/choice/${choiceId}`);
            const choice = response.data;
            onSelectEdge(choiceId);
            setSelectedChoice(choice);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching choice: ", error);
        }
    };

    /**
     * Retrieves a group by its ID from the local `groups` state.
     * If found, sets it as the selected group and selects the corresponding group on the canvas.
     *
     * @async
     * @param {string} groupId - The ID of the group to retrieve.
     * @throws Will log an error if the group is not found or if an error occurs during execution.
     */
    const getGroup = async (groupId) => {
        try {
            const group = groups.find((group) => group._id === groupId);
            if (!group) {
                throw new Error("Group not found");
            }
            setSelectedGroup(group);
            onSelectGroup(groupId);
        } catch (error) {
            console.error("Error fetching group: ", error);
        }
    };

    /**
     * Creates a copy of an existing node, including its data and self-referential edges.
     *
     * - Clones the node's data and creates a new situation in the backend.
     * - Offsets the new node's position slightly for distinction.
     * - Copies self-referential edges and creates new corresponding choices in the backend.
     * - Updates the `nodes` and `edges` state with the new node and edges.
     *
     * @async
     * @param {string} nodeId - The ID of the node to copy.
     * @throws Will log an error if the node is not found or if an error occurs during execution.
     */
    const copyNode = async (nodeId) => {
        try {
            const originalNode = nodes.find((node) => node.id === nodeId);

            if (!originalNode) {
                console.error("Original node not found");
                return;
            }

            console.log("Original Node:", originalNode);

            const newSituationData = {
                title: "", // Empty title for the new situation
                text: "", // Empty text for the new situation
                isStart: originalNode.data.isStart,
                isEnd: originalNode.data.isEnd,
                isFreeResponse: originalNode.data.isFreeResponse,
                isUserSubmitted: originalNode.data.isUserSubmitted,
                isLogged: originalNode.data.isLogged,
                scenario: originalNode.data.scenario, // Scenario ID from the original node
            };

            const newSituation = await createSituation(newSituationData);

            console.log("New Situation:", newSituation);

            const newNode = {
                ...originalNode,
                id: newSituation._id,
                position: {
                    x: originalNode.position.x + 50, // Offset position for distinction
                    y: originalNode.position.y + 50,
                },
                data: { ...originalNode.data, label: "Untitled", fillColor: "#ffffff" },
                selected: false, // Ensure the new node is not selected initially
            };

            const updatedNodes = [...nodes, newNode];

            const originalChoices = edges.filter(
                (edge) => edge.source === nodeId && edge.source === edge.target
            ); // Self-referential edges
            const newEdges = [];

            for (const originalChoice of originalChoices) {
                const newChoice = await createChoice(
                    newNode.id,
                    newNode.id,
                    originalChoice.data.choiceType === "hint",
                    true
                );
                const newEdge = {
                    ...originalChoice,
                    id: newChoice._id,
                    source: newNode.id,
                    target: newNode.id,
                    data: {
                        ...originalChoice.data,
                        label: newChoice.title || "Untitled",
                        choiceType: newChoice.hasHint
                            ? "hint"
                            : newChoice.isMLFRQ
                            ? "mlfrq"
                            : "",
                    },
                };
                newEdges.push(newEdge);
            }

            const updatedEdges = [...edges, ...newEdges];

            await updateNodes(updatedNodes);
            await updateEdges(updatedEdges);
        } catch (error) {
            console.error("Error copying node:", error);
        }
    };


    /**
     * Validates the graph for any errors before publishing.
     * If errors are found, they are set in the `graphErrors` state to prevent publishing.
     * If no errors are found, the graph is published, and the user is redirected to the home page.
     */
    const handlePublish = () => {
        const errors = checkValidity(nodes, edges);

        if (errors.length > 0) {
            setGraphErrors(errors);
        } else {
            const situationIds = nodes.map((node) => node.id);
            const data = {
                situations: situationIds,
                isPublished: true,
                isEditing: false,
            };

            const currentTime = Date.now();

            if (scenario.isPublished) {
                data.lastUpdated = currentTime;
            } else {
                data.publishedAt = currentTime;
                data.lastUpdated = currentTime;
            }

            axios
                .patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario?._id}`, data)
                .then(() => {
                    navigate('/home');
                })
                .catch((error) => {
                    console.error(`Error publishing: ${error}`);
                });
        }
    };

    /**
     * Selects a single node on the canvas.
     * Sets the node as the selected node and clears other selections (edges, groups, and choices).
     *
     * @param {string} nodeId - The ID of the node to select.
     */
    const onSelectNode = (nodeId) => {
        setSelectedNodeId(nodeId);
        setSelectedNodeIds([nodeId]);
        setSelectedEdgeId(null);
        setSelectedChoice(null);
        setSelectedGroup(null);
        setEdgeConnectingNodeId(null); // Exit edge-connecting mode
    };

    /**
     * Selects multiple nodes on the canvas.
     * Sets the provided node IDs as the selected nodes and clears other selections (edges, groups, and choices).
     *
     * @param {Array<string>} nodeIds - The IDs of the nodes to select.
     */
    const onSelectNodes = (nodeIds) => {
        setSelectedNodeIds(nodeIds);
        setSelectedEdgeId(null);
        setSelectedChoice(null);
        setSelectedGroup(null);
        setEdgeConnectingNodeId(null); // Exit edge-connecting mode
    };

    /**
     * Selects an edge on the canvas.
     * Sets the edge as the selected edge and clears other selections (nodes, groups, and choices).
     *
     * @param {string} edgeId - The ID of the edge to select.
     */
    const onSelectEdge = (edgeId) => {
        setSelectedEdgeId(edgeId);
        setSelectedNodeId(null);
        setSelectedNodeIds([]);
        setSelectedSituation(null);
        setSelectedGroup(null);
        setEdgeConnectingNodeId(null); // Exit edge-connecting mode
    };

    /**
     * Selects a group on the canvas.
     * Sets the group as the selected group and clears other selections (nodes, edges, and choices).
     *
     * @param {string} groupId - The ID of the group to select.
     */
    const onSelectGroup = (groupId) => {
        setSelectedNodeId(null);
        setSelectedNodeIds([]);
        setSelectedEdgeId(null);
        setSelectedSituation(null);
        setSelectedChoice(null);
        setEdgeConnectingNodeId(null); // Exit edge-connecting mode
    };

    /**
     * Handles a canvas click event to clear all selections.
     * Clears the selected nodes, edges, groups, and choices, and exits edge-connecting mode.
     */
    const onCanvasClick = () => {
        setSelectedEdgeId(null);
        setSelectedNodeId(null);
        setSelectedNodeIds([]);
        setSelectedSituation(null);
        setSelectedChoice(null);
        setSelectedGroup(null);
        setEdgeConnectingNodeId(null); // Exit edge-connecting mode
    };

    /**
     * Handles the start of a drag event for a node.
     * Sets the dragging state to `true` and stores the node type in the event's data transfer.
     *
     * @param {DragEvent} event - The drag event.
     * @param {string} nodeType - The type of the node being dragged.
     */
    const onDragStart = useCallback((event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
        setIsDragging(true);
    }, []);

    /**
     * Handles the end of a drag event.
     * Sets the dragging state to `false`.
     */
    const onDragEnd = useCallback(() => {
        setIsDragging(false);
    }, []);


    /**
     * Creates a new node at the center of the canvas.
     *
     * - Determines the center position of the canvas using the current zoom level and canvas bounds.
     * - Adds a slight random offset to the position for better visibility.
     * - Creates a new situation in the backend and associates it with the new node.
     * - Updates the `nodes` state and sends the updated nodes to the backend.
     *
     * @async
     * @param {string} nodeType - The type of the node to create. Can be 'startNode', 'endNode', or other node types.
     * @throws Will log an error if required functions or DOM references are unavailable or if an error occurs during the request.
     */
    const createNodeAtCenter = async (nodeType) => {
        if (!getZoomFunc || !projectFunc || !reactFlowWrapper || !reactFlowWrapper.current) {
            console.error('getZoom, project function, or reactFlowWrapper is not available.');
            return;
        }

        const getZoom = getZoomFunc;
        const project = projectFunc;

        const zoomLevel = getZoom();

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

        try {
            const nodeWidth = 120 * zoomLevel;
            const nodeHeight = 50 * zoomLevel;

            const centerX = reactFlowBounds.width / 2 - nodeWidth / 2;
            const centerY = reactFlowBounds.height / 2 - nodeHeight / 2;

            // Add random offset
            const offsetX = Math.random() * 50 - 25; // Random number between -25 and +25
            const offsetY = Math.random() * 50 - 25;

            const clientX = centerX + offsetX;
            const clientY = centerY + offsetY;

            const position = project({ x: clientX, y: clientY });

            // Prepare the new situation data
            const newSituationData = {
                isLogged: scenario.isLogged,
                text: '', // Default empty text
                isStart: nodeType === 'startNode',
                isEnd: nodeType === 'endNode',
            };

            // Call createSituation with the prepared data
            const newSituation = await createSituation(newSituationData);

            // Determine the endpointType based on nodeType
            const endpointType =
                nodeType === 'startNode'
                    ? 'start'
                    : nodeType === 'endNode'
                    ? 'end'
                    : undefined;

            // Create the new node
            const newNode = {
                id: newSituation._id,
                type: 'situation',
                position: position,
                data: {
                    label: newSituation.text || 'Untitled',
                    endpointType: endpointType,
                },
            };

            // Update the nodes and canvas
            const updatedNodes = [...nodes, newNode];
            setNodes(updatedNodes);
            updateNodes(updatedNodes);
        } catch (error) {
            console.error(`Error creating a new situation: ${error}`);
        }
    };

    

    return (
        <CanvasContext.Provider value={{
            scenario, setScenario, nodes, setNodes, edges, setEdges,
            selectedNodeId, setSelectedNodeId, selectedNodeIds, setSelectedNodeIds,
            selectedEdgeId, setSelectedEdgeId, selectAllTriggered, setSelectAllTriggered, groups, situations,
            isDragging, setIsDragging, hasToc, setHasToc, scenarioTitle, isLogged, setIsLogged, logOrder, setLogOrder,
            isEditing, isPublished, onSelectNode, onSelectNodes, onSelectEdge, onSelectGroup,
            onCanvasClick, updateNodes, updateEdges, onDragStart, onDragEnd, createSituation, createChoice,
            updateSituation, setSelectedSituation, updateChoice, deleteSituation, createGroup, updateGroup,
            deleteGroup, deleteChoice, selectedSituation, selectedChoice, setSelectedChoice, selectedGroup,
            getSituation, getChoice, getGroup, setSelectedGroup, graphErrors, setGraphErrors,
            activeEditTab, setActiveEditTab, fetchSituationsAndGroups, copyNode, showTocEditor,
            setShowTocEditor, loading, setLoading, centerNodeInView, keys, setKeys, setSituations, handlePublish, createNodeAtCenter,
            setGetZoomFunc, setProjectFunc, setReactFlowWrapper, edgeConnectingNodeId, setEdgeConnectingNodeId,
        }}>
            {children}
        </CanvasContext.Provider>
    );
};

export default CanvasContext;
