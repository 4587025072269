import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './styles/Logs.module.css';
import { AiOutlineClose } from 'react-icons/ai';
import { FaDownload } from 'react-icons/fa';

const Logs = ({ isMobile, userId }) => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [logsPerPage] = useState(10);
    const [expandedLogs, setExpandedLogs] = useState({});
    
    useEffect(() => {
        fetchLogs();
    }, [userId]);

    const fetchLogs = () => {
        setLoading(true);
        setError(null);
        axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/logs`, { withCredentials: true })
            .then(res => {
                setLogs(res.data || []); // Ensure logs is an array
                setLoading(false);
            })
            .catch(err => {
                setError('Error while getting logs');
                setLoading(false);
            });
    };

    const handleDeleteLog = (event, logId) => {
        event.stopPropagation();
        axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/logs/${logId}`, { withCredentials: true })
            .then(() => {
                setLogs(logs.filter(log => log._id !== logId));
            })
            .catch(err => {
                setError('Error while deleting log');
            });
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
        return new Date(dateString).toLocaleString('en-US', options).replace(',', '');
    };
    
    const toggleExpandLog = (logIndex) => {
        setExpandedLogs(prevState => ({
            ...prevState,
            [logIndex]: !prevState[logIndex]
        }));
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredLogs = logs.filter(log =>
        log.viewer?.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        log.scenario?.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Sort logs by date in descending order
    const sortedLogs = filteredLogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const indexOfLastLog = currentPage * logsPerPage;
    const indexOfFirstLog = indexOfLastLog - logsPerPage;
    const currentLogs = sortedLogs.slice(indexOfFirstLog, indexOfLastLog);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const processDecisions = (decisions) => {
        let processedDecisions = [];
        let currentSituation = null;
        let choicesInputs = [];

        decisions.forEach((decision, index) => {
            if (decision.type === 'situation') {
                // If currentSituation exists, push it to processedDecisions with its choices/inputs
                if (currentSituation && currentSituation.title !== decision.title) {
                    processedDecisions.push({
                        ...currentSituation,
                        choicesInputs: choicesInputs.join(' | ')
                    });
                    choicesInputs = [];
                }
                // Check if the next decision is a duplicate situation
                const nextDecision = decisions[index + 1];
                if (!nextDecision || nextDecision.type !== 'situation' || nextDecision.title !== decision.title) {
                    currentSituation = decision;
                }
            } else if (decision.type === 'choice' || decision.type === 'input') {
                choicesInputs.push(decision.type === 'input' ? decision.userInput : decision.title);
            }
        });

        // Ensure the last situation is included
        if (currentSituation) {
            processedDecisions.push({
                ...currentSituation,
                choicesInputs: choicesInputs.join(' | ')
            });
        }

        return processedDecisions;
    };

    const orderDecisions = (processedDecisions, logOrder) => {
        if (!logOrder) {
            return processedDecisions;
        }

        // Create a map for logOrder to determine the index of each situation
        const logOrderMap = logOrder.reduce((acc, situationId, index) => {
            acc[situationId] = index;
            return acc;
        }, {});

        // Sort decisions based on logOrder
        return processedDecisions.sort((a, b) => logOrderMap[a.id] - logOrderMap[b.id]);
    };

    const handleDownloadLog = (log) => {
        const blob = new Blob([JSON.stringify(log, null, 2)], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${log.viewer?.username || 'unknown'}_${log.scenario?.title || 'unknown'}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <>
            <div className={styles.searchBar}>
                <input
                    type="text"
                    placeholder="Search logs by username or scenario title..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className={styles.searchInput}
                />
            </div>
            {loading && <p>Loading logs...</p>}
            {error && <p className={styles.error}>{error}</p>}
            {!loading && logs.length === 0 && <p>No Logs!</p>}
            {currentLogs.map((log, logIndex) => (
                <div
                    key={logIndex}
                    className={`${isMobile ? styles.logMobile : styles.log} ${log.expanded ? styles.expanded : ''}`}
                >
                    <h3 onClick={() => toggleExpandLog(logIndex)}>
                        <strong>{log.viewer ? log.viewer.username : 'Unknown'}</strong> | <strong>{log.scenario ? log.scenario.title : 'Unknown'}</strong>
                    </h3>
                    <p>User: {log.viewer ? log.viewer.username : 'Unknown'}</p>
                    <p>Scenario: {log.scenario ? log.scenario.title : 'Unknown'}</p>
                    <p>Completed: {log.isCompleted ? 'Yes' : 'No'}</p>
                    <FaDownload
                        className={styles.downloadIcon}
                        onClick={() => handleDownloadLog(log)}
                    />
                    <AiOutlineClose className={styles.deleteLog} onClick={(event) => handleDeleteLog(event, log._id)} />

                    {expandedLogs[logIndex] && (
                        <div>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>Time</th>
                                        <th>Situation</th>
                                        <th>Choice / Input</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(log.scenario ? orderDecisions(processDecisions(log.decisions), log.scenario.logOrder) : processDecisions(log.decisions))
                                        .map((decision, decisionIndex) => (
                                            <tr key={decisionIndex}>
                                                <td>{formatDate(decision.createdAt)}</td>
                                                <td>{decision.title}</td>
                                                <td>{decision.choicesInputs}</td>
                                            </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            ))}
            <div className={styles.pagination}>
                {Array.from({ length: Math.ceil(filteredLogs.length / logsPerPage) }, (_, i) => (
                    <button
                        key={i + 1}
                        onClick={() => paginate(i + 1)}
                        className={currentPage === i + 1 ? styles.activePage : ''}
                    >
                        {i + 1}
                    </button>
                ))}
            </div>
        </>
    );
};

export default Logs;
