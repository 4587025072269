import React, { useContext } from 'react';
import styles from './styles/CreateSituation.module.css';
import CanvasContext from '../../../context/CanvasContext';
import SituationNode from './SituationNode';

/**
 * A component for creating new situations in the canvas. 
 * Supports both drag-and-drop (non-mobile) and click-to-create (mobile) modes.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.collapsePanel - A function to collapse the side panel.
 * @param {boolean} props.isMobile - Indicates whether the app is in mobile mode.
 * @returns {JSX.Element} The rendered component.
 */
const CreateSituation = ({ collapsePanel, isMobile }) => {
    const { createNodeAtCenter, onDragStart } = useContext(CanvasContext);

    /**
     * Handles node creation when a node type is clicked in mobile mode.
     *
     * @param {string} nodeType - The type of node to create (e.g., 'ovalNode', 'startNode', 'endNode').
     */
    const handleNodeClick = (nodeType) => {
        if (isMobile) {
            createNodeAtCenter(nodeType);
            collapsePanel();
        }
    };

    /**
     * Handles drag-and-drop functionality for creating nodes in non-mobile mode.
     * Sets a custom drag image and calls the context's drag start handler.
     *
     * @param {DragEvent} event - The drag event.
     * @param {string} nodeType - The type of node being dragged.
     */
    const handleDragStart = (event, nodeType) => {
        if (!isMobile) {
            const dragElement = event.currentTarget.cloneNode(true);

            dragElement.style.position = 'absolute';
            dragElement.style.top = '-1000px';
            dragElement.style.left = '-1000px';

            document.body.appendChild(dragElement);

            event.dataTransfer.setDragImage(
                dragElement,
                dragElement.offsetWidth / 2,
                dragElement.offsetHeight / 2
            );

            setTimeout(() => {
                document.body.removeChild(dragElement);
            }, 0);

            onDragStart(event, nodeType);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.nodeTypes}>
                {/* Situation Node */}
                <div
                    draggable={!isMobile}
                    onClick={isMobile ? () => handleNodeClick('ovalNode') : null}
                    onDragStart={(event) => handleDragStart(event, 'ovalNode')}
                >
                    <SituationNode data={{ label: 'Situation' }} showHandles={false} />
                </div>

                {/* Start Node */}
                <div
                    draggable={!isMobile}
                    onClick={isMobile ? () => handleNodeClick('startNode') : null}
                    onDragStart={(event) => handleDragStart(event, 'startNode')}
                >
                    <SituationNode
                        data={{ label: 'START', endpointType: 'start' }}
                        showHandles={false}
                    />
                </div>

                {/* End Node */}
                <div
                    draggable={!isMobile}
                    onClick={isMobile ? () => handleNodeClick('endNode') : null}
                    onDragStart={(event) => handleDragStart(event, 'endNode')}
                >
                    <SituationNode
                        data={{ label: 'END', endpointType: 'end' }}
                        showHandles={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default CreateSituation;
